import posthog from 'posthog-js'
import { store as rootStore } from '../../redux/store'
import { setInAppMessage } from '../../redux/ui-reducer';

export const checkInteractionAndTriggerMessage = (interactionName) => {

  const interactionPayload = posthog.getFeatureFlagPayload(
    'show_in_app_message_user_interaction'
  )
  if (interactionPayload && interactionPayload.messages) {
    const interactionMessage = interactionPayload.messages[interactionName]

    if (interactionMessage) {
      // If the message type is a tooltip, find the element using targetId and construct the selector
      if (interactionMessage.type === 'tooltip' && interactionMessage.targetId) {
        const selector = `div[title="${interactionName}"]`
        const element = document.querySelector(selector)

        if (element) {
          interactionMessage.anchorEl = element
        }
      }
      triggerInAppMessage(interactionMessage)
    }
  }
}
export const triggerInAppMessage = (message) => {
  const shownMessages = JSON.parse(localStorage.getItem('shownMessagesDocs') || '[]')

  if (!shownMessages.includes(message.id)) {
    rootStore.dispatch(setInAppMessage(message))
    shownMessages.push(message.id)
    localStorage.setItem('shownMessagesDocs', JSON.stringify(shownMessages))
  }
}
