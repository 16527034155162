import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './auth-reducer'
import uiReducer from './ui-reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
})

export default rootReducer
