import React from 'react'
import { Tooltip, IconButton, Typography, Button, Box, ClickAwayListener, TooltipProps } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import colors from '../../../styling'
const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
  },
  buttonContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 8,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  closeButton: {
    color: '#fff',
  },
}))

// Custom styled Tooltip to include an arrow and custom styles
const ArrowTooltip = styled(
  React.forwardRef((props, ref) => {
    const { className, ...other } = props
    return <Tooltip {...other} classes={{ popper: className }} ref={ref} />
  })
)(() => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: colors.ui.blueLight,
    color: '#fff',
    maxWidth: 300,
    fontSize: 14,
    borderRadius: 8,
    padding: 16,
    position: 'relative',
    pointerEvents: 'auto',
    zIndex: 1300,
  },
  '& .MuiTooltip-arrow': {
    color: colors.ui.blueLight,
  },
}))

const TooltipMessage = ({ anchorEl, messageToShow, handleClose, handleButtonClick }) => {
  const classes = useStyles()

  // Don't render if there's no anchor element or the message type is not 'tooltip'
  if (!anchorEl || messageToShow.type !== 'tooltip') return null

  const { title, content, placement } = messageToShow

  // Handle clicks outside the tooltip and anchor element
  const handleClickAway = (event) => {
    const target = event.target

    if (anchorEl.contains(target) || document.querySelector('.MuiTooltip-tooltip')?.contains(target)) {
      return
    }

    handleClose()
  }

  return (
    <>
      {/* Overlay to dim the rest of the page */}
      <div className={classes.overlay} onClick={handleClose} />

      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <ArrowTooltip
            open
            arrow
            placement={placement || 'top'}
            title={
              <div className={classes.tooltipContent}>
                <div className={classes.headerContainer}>
                  {title && (
                    <Typography className={classes.title} variant="subtitle1">
                      {title}
                    </Typography>
                  )}
                  <IconButton
                    className={classes.closeButton}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation() // Prevent triggering ClickAwayListener
                      handleClose()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>

                {content.map((element, index) =>
                  element.type === 'text' ? (
                    <Typography key={index} variant="body1" style={{ marginTop: 8 }}>
                      {element.text}
                    </Typography>
                  ) : null
                )}

                {content.some((el) => el.type === 'button') && (
                  <Box className={classes.buttonContainer}>
                    {content.map((element, index) =>
                      element.type === 'button' ? (
                        <Button
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering ClickAwayListener
                            handleButtonClick(element.action, element.url)
                          }}
                          style={{ color: '#fff' }}
                        >
                          {element.text}
                        </Button>
                      ) : null
                    )}
                  </Box>
                )}
              </div>
            }
            PopperProps={{
              anchorEl,
              disablePortal: false,
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
              ],
            }}
          >
            <span />
          </ArrowTooltip>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default TooltipMessage
