import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import posthog from 'posthog-js'
import { triggerInAppMessage } from '../utils'

const PageViewMessage = () => {
  const location = useLocation()

  useEffect(() => {
    const triggerMessage = () => {
      const pageViewPayload = posthog.getFeatureFlagPayload('show_in_app_message_page_view')

      if (pageViewPayload && pageViewPayload.messages) {
        // Create a unique key using both pathname and hash
        const locationKey = `${location.pathname}${location.hash}`

        // Check if there's a specific message for the current location
        const pageMessage = pageViewPayload.messages[locationKey]
        if (pageMessage) {
          triggerInAppMessage(pageMessage)
        }
      }
    }

    triggerMessage()

  }, [location])

  return null
}

export default PageViewMessage
