import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  inAppMessage: null,
  onBoardingPostponed: false
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setInAppMessage: (state, action) => {
      state.inAppMessage = action.payload
    },
    clearInAppMessage: (state) => {
      state.inAppMessage = null
    },
    skipOnboardingForNow: (state) => {
      state.onBoardingPostponed = true
    },
    resetInAppMessaging: (state) => {
      state.inAppMessage = null,
      state.onBoardingPostponed = false
    }
  },
})

export const { setInAppMessage, clearInAppMessage, skipOnboardingForNow, resetInAppMessaging } = uiSlice.actions
export default uiSlice.reducer
