import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TooltipMessage from './message-components/tooltip-message'
import DialogMessage from './message-components/dialog-message'
import NotificationMessage from './message-components/notification-message'
import { clearInAppMessage } from '../../redux/ui-reducer'

const InAppMessaging = () => {
  const messageToShow = useSelector((state) => state.ui.inAppMessage)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (messageToShow?.type === 'tooltip' && messageToShow.targetSelector) {
      if (!messageToShow.anchorEl) {
        const element = document.querySelector(`div[title="${messageToShow.targetSelector}"]`)
        if (element) {
          setAnchorEl(element)
        } else {
          setAnchorEl(null)
        }
      }
    } else {
      setAnchorEl(null)
    }
  }, [messageToShow])
  
  const handleClose = () => {
    dispatch(clearInAppMessage())
  }

  const handleButtonClick = (action , url) => {
    if (action === 'navigate' && url) {
      window.location.href = url
    }
    handleClose()
  }

  if (!messageToShow) return null

  return (
    <>
      {messageToShow.type === 'tooltip' && (
        <TooltipMessage
          anchorEl={anchorEl || messageToShow?.anchorEl}
          messageToShow={messageToShow}
          handleClose={handleClose}
          handleButtonClick={handleButtonClick}
        />
      )}
      {messageToShow.type === 'dialog' && (
        <DialogMessage messageToShow={messageToShow} handleClose={handleClose} />
      )}
      {messageToShow.type === 'notification' && (
        <NotificationMessage messageToShow={messageToShow} handleClose={handleClose} />
      )}
    </>
  )
}

export default InAppMessaging
