/* eslint no-restricted-globals:0 */
import auth0 from 'auth0-js'
class AuthO {
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid',
  })

  authorize = () => {
    this.auth0.authorize()
  }

  logout = () => {
    this.auth0.logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })
  }

  handleAuthentication = (cb) => {
    this.auth0.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        cb(null, authResult)
      } else if (error) {
        cb(error)
      } else {
        cb(null, null)
      }
    })
  }
}

const auth = new AuthO()

export default auth
