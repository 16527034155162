import React from 'react'
import Cookies from 'js-cookie'
import auth0 from './helpers/auth0'

const PrivateRoute = (PrivateComponent) => {
  const Private = (props) => {
    const token = Cookies.get('auth-token')
    if (!token) {
        auth0.authorize()
        return null
    }else{
      return <PrivateComponent {...props} />
    }
    
  }
  return Private
}

export default PrivateRoute
