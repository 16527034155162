import React, { useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import colors from '../../../styling'
import SnackBar from '../../../context/snackbar'

const NotificationMessage = ({ messageToShow, handleClose }) => {
  const { enqueueSnackbar, closeSnackbar } = SnackBar.Consumer()

  useEffect(() => {
    // Only run if there's a message to show and it's of type 'notification'
    if (messageToShow?.type === 'notification') {
      const textElement = messageToShow.content?.find((el) => el.type === 'text')
      if (textElement) {
        enqueueSnackbar(textElement.text, {
          style: { backgroundColor: colors.ui.blueLight } ,
          autoHideDuration: 15000,
          onClose: handleClose,
          action: (key) => (
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                closeSnackbar(key)
                handleClose()
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        })
      }
    }
  }, [messageToShow, enqueueSnackbar, closeSnackbar, handleClose])

  return null
}

export default NotificationMessage
