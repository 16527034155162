import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: null,
  isAuthenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.userInfo = action.payload
      state.isAuthenticated = true
    },
    logout: (state) => {
      state.userInfo = null
      state.isAuthenticated = false
    },
  },
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer
