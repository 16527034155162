import React, { useState, useEffect } from 'react'
import { Tooltip, Button, Box, Typography, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import colors from '../../../../styling'
import { updateOnboardingStatusInPosthog } from '../../../../services/posthog'

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  tooltipPaper: {
    backgroundColor: colors.ui.blueLight,
    color: '#fff',
    maxWidth: 300,
    borderRadius: '8px',
    border: 0,
    padding: '16px',
    zIndex: 1300,
    pointerEvents: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  closeButton: {
    color: '#fff',
  },
  tooltipArrow: {
    color: colors.ui.blueLight,
    fontSize: '1.5rem',
  },
  tooltip: {
    backgroundColor: colors.ui.blueLight,
    color: '#fff',
    minWidth: 300,
    padding: '4px 8px',
    boxShadow: '0px 4px 20px rgba(0,0,0,0.5)',
  },
}))


const OnboardingMessage = ({ steps, onClose }) => {
  const classes = useStyles()
  const [currentStep, setCurrentStep] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    const currentStepData = steps[currentStep]
    const element = document.querySelector(`div[title='${currentStepData.selector}']`)
    if (element) {
      setAnchorEl(element)
    } else {
      onClose()
    }
  }, [currentStep, steps, onClose])

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      handleFinish()
    }
  }

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }
  const handleFinish = () => {
    setCurrentStep(0)
    updateOnboardingStatusInPosthog()
    onClose()
  }

  if (!anchorEl) {
    return null
  }

  return (
    <>
      <div
        className={classes.overlay}
        onClick={(e) => {
          e.stopPropagation()
          onClose()
        }}
      />
      <Tooltip
        open
        arrow
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
        title={
          <div
            className={classes.tooltipPaper}
            onClick={(e) => e.stopPropagation()} // Prevent clicks inside tooltip from closing it
          >
            <div className={classes.headerContainer}>
              {steps[currentStep].title && (
                <Typography className={classes.title} variant="subtitle1">
                  {steps[currentStep].title}
                </Typography>
              )}
              <IconButton
                className={classes.closeButton}
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  onClose()
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Typography variant="body1">{steps[currentStep].content}</Typography>
            <Box className={classes.buttonContainer}>
              {currentStep > 0 && (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation()
                    handlePrevious()
                  }}
                  style={{ color: '#fff' }}
                >
                  Previous
                </Button>
              )}
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation()
                  handleNext()
                }}
                style={{ color: '#fff', backgroundColor: '#007BFF' }}
              >
                {currentStep < steps.length - 1 ? 'Next' : 'Finish'}
              </Button>
            </Box>
          </div>
        }
        placement={steps[currentStep].placement || 'top'}
        PopperProps={{
          anchorEl: anchorEl,
          disablePortal: false,
          modifiers: {
            offset: {
              enabled: true,
              offset: '0, 8',
            },
          },
        }}
      >
        <span />
      </Tooltip>
    </>
  )
}

export default OnboardingMessage
