import React, { useState, useEffect } from 'react'
import WelcomeDialog from './welcome-dialog'
import OnboardingMessage from './onboarding-message'
import posthog from 'posthog-js'
import { useDispatch, useSelector } from 'react-redux'
import { skipOnboardingForNow } from '../../../../redux/ui-reducer'

const OnboardingManager = () => {
  const [onboardingStarted, setOnboardingStarted] = useState(false)
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(true)
  const [onboardingPayload, setOnboardingPayload] = useState(null)
  const dispatch = useDispatch()
  const onBoardingPostponed = useSelector((state) => state.ui.onBoardingPostponed)

  useEffect(() => {
    if (onboardingStarted) {
      setShowWelcomeDialog(false)
    }
  }, [onboardingStarted])

  useEffect(() => {

    posthog.onFeatureFlags(() => {
      const payload = posthog.getFeatureFlagPayload('show_onboarding_steps')

      setOnboardingPayload(payload)
    })
  }, [])

  if (!onboardingPayload?.onboardingSteps?.length) return null

  const onboardingSteps = onboardingPayload.onboardingSteps
  const onboardingDialogContent = onboardingPayload.welcomeDialog

  // Start onboarding when the user accepts
  const handleStartOnboarding = () => {
    setOnboardingStarted(true)
    setShowWelcomeDialog(false)
  }

  const handleSkipOnboarding = () => {
    dispatch(skipOnboardingForNow())
    setShowWelcomeDialog(false)

  }

  const handleOnboardingClose = () => {
    setOnboardingStarted(false)
  }

  return (
    <>
      {/* Shows WelcomeDialog if onboarding hasn't been started */}
      {!onboardingStarted && showWelcomeDialog && !onBoardingPostponed && (
        <WelcomeDialog
        
          open={showWelcomeDialog}
          onStartOnboarding={handleStartOnboarding}
          onSkipOnboarding={handleSkipOnboarding}
          content={onboardingDialogContent}
        />
      )}

      {/* Manages onboarding steps */}
      {onboardingStarted && <OnboardingMessage steps={onboardingSteps} onClose={handleOnboardingClose} />}
    </>
  )
}

export default OnboardingManager
