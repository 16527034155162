import React from "react"
import Cookies from "js-cookie"
import auth0 from "../helpers/auth0"
import axios from "../helpers/axios"
import { useDispatch } from "react-redux"
import { loginUser } from "../redux/auth-actions"

// ===========
// COMPONENT
// ===========
const Login = () => {
  const dispatch = useDispatch()

  auth0.handleAuthentication((error, authResult) => {
    if (!error && !authResult) {
      auth0.authorize()
    }
    if (error) return error

    axios
      .post('/login', { ...authResult })
      .then((response) => {

        var tokenExpiryDate = new Date()
        tokenExpiryDate.setDate(tokenExpiryDate.getDate() + 1)

        Cookies.set('auth-token', response.data.authToken, {
          expires: tokenExpiryDate,
        })
        const userInfo = {
          firstName: response.data.firstName,
          organisationName: response.data.organisationName,
          email: response.data.email,
        }
        dispatch(loginUser(userInfo))
        
      })
      .catch((e) => {
        Cookies.remove('auth-token')
        auth0.logout()
        window.location.href = "/"
        throw e
      })
  })

  return <div>Logging in...</div>
}

export default Login
