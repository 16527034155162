import ReactDOM from "react-dom"
import App from "./app"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import SnackBar from './context/snackbar'

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
})

const rootElement = document.getElementById("root")

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <SnackBar.Provider>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
      </SnackBar.Provider>
    </PersistGate>
  </Provider>,
  rootElement
)
