import React, { lazy, Suspense } from "react"
import { Redirect, Route, Switch, BrowserRouter, Link } from "react-router-dom"
import "@stoplight/elements/styles.min.css"
import PrivateRoute from "./private"
import Login from "./components/login"
import InAppMessage from "./components/in-app-messaging"
const Api = lazy(() =>
  import(/* webpackChunkName: "docs" */ "./components/api")
)
const Access = lazy(() =>
  import(/* webpackChunkName: "docs" */ "./components/access")
)
const NotFound = lazy(() =>
  import(/* webpackChunkName: "404" */ "./components/404")
)
import "./styles.css"
import Logo from "./images/orpheus-logo-with-text.png"
import { userClickedOnAccessButton } from "./services/interaction-data"
import { recordUserInteraction } from "./services/posthog"

const Navbar = () => {
  return (
    <div className="topnav">
      <img src={Logo} alt="Logo" className="logo" />
      <a style={{ fontSize: "1rem" }} href="/api/">
        API
      </a>
      <Link
        style={{ fontSize: "1rem" }}
        to="/access"
        onClick={() => recordUserInteraction(userClickedOnAccessButton)}
      >
        Access
      </Link>
    </div>
  )
}

const App = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <InAppMessage />
        <Navbar></Navbar>
        <Switch>
          <Route exact path="/">
            <Redirect to={"/api/"} />
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/access" component={PrivateRoute(Access)} />
          <Route path="/api" component={PrivateRoute(Api)} />
          <Route component={PrivateRoute(NotFound)} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
