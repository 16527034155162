import Cookies from 'js-cookie'
import axios from 'axios'

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get('auth-token')
    config.url = process.env.REACT_APP_API_DOMAIN + process.env.REACT_APP_API_ENDPOINT + config.url
    config.headers = {
      Authorization: `${token}`,
      Accept: 'application/json',
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default axios
