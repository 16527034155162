import React from 'react'
import PageViewMessage from './contextual-messages/page-view-message'
import InAppMessaging from './in-app-messaging'
import OnboardingManager from './contextual-messages/onboarding-message/index'
const InAppMessage = () => {
  return (
    <>
      {/* Tracks page views and shows corresponding messages */}
      <PageViewMessage />

      {/* Manages onboarding messages and tooltips */}
      <OnboardingManager />

      {/* Renders the actual in-app message dialog, notification or tooltip */}
      <InAppMessaging />

    </>
  )
}

export default InAppMessage
