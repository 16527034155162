import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode";

export const getJwtPayload = () => {
  try {
    const token = Cookies.get('auth-token')
    if (!token) return {}
    const decodedToken = jwtDecode(token)
    return decodedToken.data ?  decodedToken.data  : {}
  } catch (error) {
    return {}
  }
}
