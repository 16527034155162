//TODO: create global theme
const colors = {
  ui: {
    blueDark: "#03113f",
    blue: "#031454",
    blueLight: "#1c2b65",
    blueVeryLight: "#677298",
    goldDark: "#80663D",
    gold: "#a0804d",
    goldLight: "#B39970",
    greenDark: "#068F08",
    green: "#09e00c",
    greenLight: "#BEF7C0",
    greyVeryDark: "#333333",
    greyDark: "#777777",
    grey: "#ababab",
    greyLight: "#d5d5d5",
    greyVeryLight: "#f6f6f6",
    orange: "orange",
    redDark: "#9C0706",
    red: "#e00a09",
    redLight: "#F29A9C",
    black: "#000000",
    whiteSmoke: "#f5f5f5",
    snow: "#FCFCFC",
    white: "#ffffff",
    blackCow: "#484848",
    yellow: "#FFFF00",
  },
}
export default colors
