import posthog from 'posthog-js'
import { getJwtPayload } from '../auth/payload'
import { store } from '../redux/store'
import { checkInteractionAndTriggerMessage } from '../components/in-app-messaging/utils'

//attach event listener to api page that uses @stoplight/elements to record custom user interactions
export const setupApiPageEventListeners = (containerElement) => {
  const handleClick = (event) => {
    // Find the closest ancestor (or self) with a 'title' attribute
    const target = event.target.closest('div[title]')
    if (target && containerElement.contains(target)) {
      const title = target.getAttribute('title')
      const event = `Clicked on ${title ==='Overview' ? 'Getting Started' : title} endpoint`
      recordUserInteraction(event)
    }
  }

  containerElement.addEventListener('click', handleClick)

  return () => {
    containerElement.removeEventListener('click', handleClick)
  }
}



export const setupCustomAttributes = () => {
  try {
    const token = getJwtPayload() || {}
    const state = store.getState()
    const { organisationName, firstName, email } = state.auth.userInfo || {}

    posthog.register({
      userId: token.userId,
      email,
      name: firstName,
      orgId: token.organisationId,
      orgName: organisationName,
    })
  } catch (e) {
    console.error('Failed to set up custom attributes:', e)
  }
}

export const recordUserInteraction = (interactionName) => {
  if (!interactionName) {
    console.error('Cannot record interaction with no name')
    return
  }

  posthog.capture(interactionName)
  // Evaluate the feature flag for in-app messaging to handle in app messaging on predefined user event
  checkInteractionAndTriggerMessage(interactionName)
}

export const identifyUserInPostHog = () => {
  const token = getJwtPayload() || {}
  const state = store.getState()
  const { organisationName, firstName, email } = state.auth.userInfo || {}

  if (token) {
    posthog.identify(token.userId, {
      userId: token.userId,
      email,
      name: firstName,
      orgId: token.organisationId,
      orgName: organisationName,

    })

    setupCustomAttributes()
  }
}

export const clearPostHogSession = () => {
  posthog.reset()
}

export const updateOnboardingStatusInPosthog = () => {
  const userId = posthog.get_distinct_id()
  posthog.identify(userId)
  posthog.people.set({
    hasCompletedOnboarding: true,
  })
}
