import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, makeStyles, Button, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    padding: '18px',
  },
}))

const DialogMessage = ({ messageToShow, handleClose }) => {
  const classes = useStyles()

  if (messageToShow.type !== 'dialog') return null

  const handleButtonClick = (action, url) => {
    if (action === 'navigate' && url) {
      window.location.href = url
    }
    handleClose()
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="sm">
      {messageToShow.dialogTitle && (
        <DialogTitle disableTypography className={classes.titleContainer}>
          <Typography variant="h6">{messageToShow.dialogTitle}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {messageToShow.content?.map((element, index) => {
          switch (element.type) {
            case 'text':
              return (
                <Typography key={'text' + index} variant="body1">
                  {element.text}
                </Typography>
              )
            case 'button':
              return (
                <Box key={'button' + index} display="flex" justifyContent="flex-end" marginTop="20px">
                  <Button
                    key={index}
                    variant="contained"
                    color="primary"
                    onClick={() => handleButtonClick(element.action, element.url)}
                    style={{ textAlign: 'right' }}
                  >
                    {element.text}
                  </Button>
                </Box>
              )
            case 'link':
              return (
                <Box key={'link' + index} display="flex" justifyContent="flex-end" marginTop="20px">
                  <Typography key={index} variant="body1">
                    <a href={element.url} target="_blank" rel="noopener noreferrer">
                      {element.text}
                    </a>
                  </Typography>
                </Box>
              )
            default:
              return null
          }
        })}
      </DialogContent>
    </Dialog>
  )
}

export default DialogMessage
