import { login, logout } from './auth-reducer'
import Cookies from 'js-cookie'
import auth0 from '../helpers/auth0'
import { clearPostHogSession, identifyUserInPostHog, recordUserInteraction } from '../services/posthog'
import { userLoggedInSuccessfully } from '../services/interaction-data'
import { resetInAppMessaging } from './ui-reducer'


// Login action
export const loginUser = (userInfo) => (dispatch) => {
  dispatch(login(userInfo))
  identifyUserInPostHog()
  recordUserInteraction(userLoggedInSuccessfully)
  window.location.href = '/'
}

// Logout action
export const logoutUser = () => (dispatch) => {
  Cookies.remove('auth-token')
  clearPostHogSession()
  auth0.logout()
  dispatch(logout())
  dispatch(resetInAppMessaging())
  setTimeout(() => {
    window.location.href = '/'
  }, 2000)
}
